@mixin responsive_sm {
    @media (min-width: 576px) {
        @content
    }
}

;

@mixin responsive_md {
    @media (min-width: 768px) {
        @content
    }
}

;

@mixin responsive_lg {
    @media (min-width: 992px) {
        @content
    }
}

;

@mixin responsive_xl {
    @media (min-width: 1200px) {
        @content
    }
}

;

@mixin responsive_xxl {
    @media (min-width: 1400px) {
        @content
    }
}