#images {
    display: none;
    @include responsive_xl {
        @include flex(row, center, center);
        width: 100%;
        margin-top: 100px;
        gap: 10px;
        div {
            width: 100%;
            cursor: pointer;
            transition: transform .2s;
            &:hover {
                transform: scale(1.1);
                box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
                z-index: 100;
            }
            img {
                width: 100%;
            }
        }
    }
}