@import './navbar/navbar.scss';

#header {
    scroll-margin: 120px;

    .header {
        background-image: url('../../../assets/images/pictures/background.png');
        background-size: cover;
        @include size($height: 560px);
        box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
        @include flex(column, center, center);
        padding: 60px 0px;
        text-align: center;
        color: $background;
        gap: 20px;

        h1 {
            font-size: 54px;
        }

        div {
            @include flex(column, center, center);
            width: 100%;
            margin-top: 50px;
            gap: 20px;

            a {
                img {
                    float: left;
                    width: 12rem;
                    object-fit: cover;
                }
            }
        }

        @include responsive_xl {
            h1 {
                font-size: 64px;
            }

            h2 {
                font-size: 30px;
            }

            div {
                @include flex(row, center, center);
                width: 50%;
            }
        }
    }
}