* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    // font-family:;
    color: #212121;
}
    

li {
    list-style: none;
}
    

a {
    text-decoration: none;
    color: black;
}
    