// utils
@import './utils/reset';
@import './utils/mixins';
@import './utils/responsive-mixins';
@import './utils/variables';
@import './utils/extend';


// pages
@import '../../pages/Home/home.scss';
@import '../../pages/Regiser/register.scss';

// layout 
@import '../../components/Layout/Header/header.scss';
@import '../../components/Layout/Footer/footer.scss';


// box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);


// global styles
html {
    scroll-behavior: smooth;
    letter-spacing: $letterSpacing;
    font-family: $font;
    overflow-x: hidden;
}

body {
    &::before {
        content: "";
        display: block;
        margin-top: 90px;

        @include responsive_xl {
            margin-top: 100px;
        }
    }

}

.container {
    padding: 0 5%;
}