#section {
    @include flex(column, center, center);
    text-align: center;
    margin-top: 56px;
    width: 100%;
    color: $textColor;
    .text {
        @include flex(column, center, center);
        margin-top: 24px;
        width: 80%;
        p {
            margin-top: 16px;
        }
    }
    @include responsive_xl{
        @include flex(row, space-between, center);
        text-align: start;
        &:nth-child(1) {
            margin-top: 0;
        }
        .text {
            @include flex(column, center, flex-start);
        }
    }
}