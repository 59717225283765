.green-button-style {
    padding: 10px;
    color: $background;
    background-color: $buttonColor;
    box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
    border: 0px;
    border-radius: 20px;
    font-size: $fontSizeMobile;
    letter-spacing: $letterSpacing;
    font-family: $font;

    &:hover {
        cursor: pointer;
        background-color: $buttonHoverColor;
        transform: scale(1.05);

    }

    &:active {
        background-color: $buttonPressedColor ;
    }
}

.button-style {
    padding: 12px;
    color: $background;
    border: 0px;
    border-radius: 20px;
    font-size: $fontSizeMobile;
    letter-spacing: $letterSpacing;
    box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
    font-family: $font;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    &:active {
        background-color: $buttonPressedColor ;
    }
}