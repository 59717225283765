#footer {
    background-color: $footerColor;
    color: $footerTextColor;
    padding-top: 56px;
    padding-bottom: 28px;
    a {
        color: $footerTextColor;
    }
    .footer {
        @include flex(column);
        .above-section {
            @include flex(row);
            width: 100%;
            gap: 30%;
            .logo-footer {
                width: 20%;
                img {
                    @include size(80px);
                    border-radius: 50px;
                }
            }

            ul {
                width: 100%;
                line-height: $lineHeight;
                margin-bottom: 20px;
                @include flex(column, center, center);
                li, h4 {
                    width: 100%;
                }
                li {
                    &:hover {
                        color: $buttonColor;
                        cursor: pointer;
                    }
                    a {
                        &:hover {
                            color: $buttonColor;
                            cursor: pointer;
                        }
                    }
                }
                h4 {
                    margin-bottom: 8px;
                }
            }
            
        }

        .below-section {
            margin-top: 20px;
            padding-top: 28px;
            @include flex(row, center, center);
            border-top: 1px solid $borderColor;
        }
    }

    @include responsive_xl {
        .footer {
            .above-section {
                gap: 10%;
                .uls-footer {
                    width: 100%;
                    @include flex(row, space-between, flex-start);

                    ul {
                        width: 100%;
                        line-height: $lineHeight;
                        @include flex(column, center, center);
                    }
                }
            }
        }
    }
}