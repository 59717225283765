@import './section/section.scss';

#sections {
    letter-spacing: $letterSpacing;
    @include flex(column, center, center);
    margin-top: 56px;
    scroll-margin: 140px;
    width: 100%;
    .heading {
        @include flex(column, center, center);
        width: 100%;
        p.header {
            color: $buttonColor;
        }
        h2.subheader {
            color: $textColor;
            margin-top: 8px;
            text-align: center;
        }
    }
    @include responsive_xl {
        @include flex(row, space-between, flex-start);
        margin-top: 100px;
        .heading {
            @include flex(column, flex-start, flex-start);
            border-right: 1px solid $borderColor;
            @include size(30%, 200px);
            p.header {
                margin-top: 8px;
            }
            h2.subheader {
                text-align: start;
            }
        }
        .sections {
            width: 60%;
        }
    }
}