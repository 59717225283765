#slider {
    margin-top: 56px;
    .swiper-slide {
        @include flex(row, center, center);
        img {
            @include size(100%);
        }
    }
    @include responsive_xl {
        display: none;
    }
}