#about {
    margin-top: 56px;
    scroll-margin: 120px;
    width: 100%;
    letter-spacing: $letterSpacing;
    color: $textColor;
    @include flex(column, center, center);
    .heading {
        @include flex(column, center, center);
        width: 100%;
        p.header {
            color: $buttonColor;
        }
        h2.subheader {
            margin-top: 8px;
            color: $textColor;
            text-align: center;
        }
    }

    p {
        &:nth-child(2) {
            width: 100%;
            margin-top: 56px;
            text-align: center;
        }
    }

    @include responsive_xl {
        @include flex(row, space-between,center);
        margin-top: 100px;
        .heading {
            @include flex(column, flex-start, flex-start);
            border-right: 1px solid $borderColor;
            @include size(30%, 200px);
            p.header {
                margin-top: 8px;
            }
        }
        p {
            &:nth-child(2) {
                width: 60%;
                margin-top: 20px;
                text-align: start;
            }
        }
    }
}