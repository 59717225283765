@mixin flex ($direction: null, $justify: null, $align: null) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin font ($size: null, $weight: null, $spacing: null, $height: null, $align: null) {
    font-size: $size;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: $height;
    text-align: $align;
}

@mixin size ($width: null, $height: null) {
    width: $width;
    height: $height;
}