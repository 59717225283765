@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

// colors

$textColor: #343434;
$textColor2: #27565E;
$background: #FFFFFF;
$buttonColor: #43BA7F;
$buttonHoverColor: #56C18C;
$buttonPressedColor: #3CA772;
$yellowColor: #FCC231;
$orangeColor: #FF8145;
$purpleColor: #C282D1;
$borderColor: #C4C4C4;
$sectionColor: #F0EBE6;
$footerColor :#0D1118;
$footerTextColor: #6E7581;

// font styles

$fontSizeMobile: 14px;
$fontSizeDesktop: 16px;
$letterSpacing: 0.5px;
$lineHeight: 30px;

// font 

$font: 'Poppins', sans-serif;

