#contact {
    background-color: $sectionColor;
    margin-top: 56px;
    scroll-margin: 88px;
    padding-top: 20px;
    padding-bottom: 40px;
    color: $textColor2;

    a {
        color: $textColor2;

        &:hover {
            color: $buttonColor;
        }
    }

    @include flex(column, center, center);

    h2 {
        @include flex(row, center, center);
    }

    .contact {
        @include flex(column, center, center);
        @include size(90%);

        .left-side {
            @include size(100%);
            @include flex(column, center, center);
            text-align: center;
            margin-top: 8px;
            line-height: $lineHeight;
        }

        .right-side {
            @include size(100%);
            @include flex(column, center, space-between);
            margin-top: 20px;

            div {
                @include flex(column, center, center);
                gap: 10px;

                &:nth-child(2) {
                    margin-top: 10px;
                }

                span {
                    @include size(60%);
                    gap: 10px;
                    @include flex(row, center, center);

                    img {
                        @include size(30px);
                    }
                }

            }

            .instagram {
                @extend .button-style;
                background: #f09433;
                background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
            }

            .facebook {
                @extend .button-style;
                background-color: #3B5998;
            }

            .whatsapp {
                @extend .button-style;
                background-color: #21BA45;
                a {
                    color: white;
                }
            }

            .mail {
                @extend .button-style;
                background-color: #DB2828;
            }
        }
    }

    @include responsive_lg {
        padding-top: 56px;
        padding-bottom: 112px;

        .contact {
            margin-top: 56px;
            @include flex(row, space-between, center);

            .left-side {
                @include flex(column, $align: flex-start);
                text-align: inherit;
                margin-top: 0px;
                line-height: $lineHeight;
            }

            .right-side {
                margin-top: 0px;

                div {
                    @include flex(row);

                    span {
                        @include size(44%);
                        gap: 10px;
                        @include flex(row, center, center);
                    }
                }
            }
        }

    }

    @include responsive_xl {
        margin-top: 100px;
    }
}