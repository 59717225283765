#form {
    margin-top: 56px;
    margin-bottom: 56px;
    color: $background;
    @include flex(column);
    font-size: $fontSizeMobile;
    letter-spacing: $letterSpacing;
    scroll-margin: 100px;
    button {
        @extend .green-button-style;
    }
    .header {
        background-image: url('../../assets/images/pictures/background.png');
        background-size: cover;
        box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
        padding: 20px;
        text-align: center;
        width: 100%;
        p {
            margin-top: 20px;
        }
    }
    .inputs {
        color: $textColor;
        @include flex(row, center, center);
        margin-top: 30px;
        .box {
            @include flex(column, center, center);
            box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
            gap: 20px;
            width: 90%;
            padding-top: 30px;
            padding-bottom: 30px;
            .imgForm {
                @include flex(column, center, center);
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                img {
                    &:nth-child(1) {
                        width: 16px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    &:nth-child(2) {
                        width: 40%;
                    }
                }
            }
            .end-section {
                @include flex(column, center, center);
                text-align: center;
            }
            .end-button {
                width: 90%;
                margin-top: 10px;
            }
            div {
                @include flex(column, center);
                gap: 10px;
                width: 90%;
                input {
                    padding: 18px;
                    border: 1px solid $borderColor;
                    border-radius: 20px;
                    font-size: $fontSizeMobile;
                }
            }
        }
    }
    @include responsive_xl {
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        gap: 30px;
        @include flex(row, center);
        font-size: $fontSizeDesktop;
        .header {
            background-image: url('../../assets/images/pictures/background2.png');
            width: 50%;
            text-align: end;
            padding: 50px;
        }
        .inputs {
            width: 50%;
            margin-top: 0px;
        }
    }
}
.input-error {
    border-color: #fc8181;
}

.errorMessage {
    color: #fc8181;
    font-size: 14px;
}