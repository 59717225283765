#navbar {
    position: fixed;
    top: 0;
    background-color: #fff;
    color: $textColor;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(164, 164, 164, 0.50);
    z-index: 1000;

    .container {
        @include flex(row, space-between, center);
        background-color: white;

        img {
            &:nth-child(1) {
                @include size(100px)
            }
        }

        .nav-end {
            width: 50%;
            gap: 10%;
            @include flex(row, space-between, center);
        }


        ul {
            position: absolute;
            top: -150px;
            right: 0;
            background-color: white;
            width: 100%;
            @include flex(column, center, center);
            transition: .4s ease-in-out;
            z-index: -10000;

            li {
                z-index: -100000;
                border-bottom: 0px;
                padding: 16px;
                border-bottom: 0.5px solid $borderColor;
                width: 90%;
                @include flex(row, center, center);
            }
        }

        ul.active {
            top: 80px;
            z-index: -100000000;
            padding: 30px;
            border-bottom: 2px solid $borderColor;
            transition: .4s ease-in-out;

            li {
                color: $textColor;
                z-index: -10000;
            }
        }

        button {
            @extend .green-button-style;
            font-size: 14px;
        }

        @include responsive_md {
            .nav-end {
                width: 30%;
            }
        }

        @include responsive_xl {
            img {
                @include size(110px);
            }

            .nav-end {
                width: inherit;

                button {
                    font-size: $fontSizeMobile;
                }

                img {
                    display: none
                }
            }

            ul {
                @include flex(row, $align: center);
                width: 45%;
                position: static;
                background-color: inherit;
                gap: 30px;
                transition: 0s;
                z-index: 0;

                li {
                    cursor: pointer;
                    z-index: inherit;
                    padding: 0;
                    border-bottom: 0;
                    letter-spacing: $letterSpacing;
                    font-size: $fontSizeDesktop;
                    display: block;
                    width: auto;

                    &:hover,
                    &:active {

                        &::before,
                        &::after {
                            backface-visibility: hidden;
                            border-color: $buttonColor;
                            transition: width 350ms ease-in-out;
                            width: 100%;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }

                        &::before {
                            bottom: auto;
                            top: 0;
                            width: 100%;
                        }
                    }

                    &::after,
                    &::before {
                        backface-visibility: hidden;
                        border: 0.5px solid rgba($buttonColor, 0);
                        bottom: 0px;
                        content: " ";
                        display: block;
                        margin: 0 auto;
                        position: relative;
                        transition: all 280ms ease-in-out;
                        width: 0;
                    }

                }
            }
        }
    }
}